export const environment = {
	production: true,
	API_URL: 'https://app.groupfitx.com',
	name: 'production',
	websiteUrl: 'https://app.groupfitx.com',
	marketingSiteUrl: 'https://app.groupfitx.com',
	ssrIgnoreSsl: false,
	newrelic: {
		isEnabled: true,
		accountId: '4028416',
		trustKey: '4028416',
		agentId: '1120294849',
		licenseKey: 'NRJS-3fb531c3a5168c05bb1',
		applicationId: '1120294849',
		assetDomain: 'nr-js.groupfitx.com',
		beaconDomain: 'nr.groupfitx.com',
	},
};
