export class ReleaseTrackExerciseModel {
	constructor(releaseTrackId: string, exerciseId: string, exerciseName: string) {
		this.releaseTrackId = releaseTrackId;
		this.exerciseId = exerciseId;
		this.exerciseName = exerciseName;
	}

	private releaseTrackId: string;
	private exerciseId: string;
	private exerciseName: string;

	public getReleaseTrackId(): string {
		return this.releaseTrackId;
	}

	public getExerciseId(): string {
		return this.exerciseId;
	}

	public getExerciseName(): string {
		return this.exerciseName;
	}
}

export class ReleaseTrackMusicArtistModel {
	constructor(musicArtistId: string, artistName: string, isFeaturedArtist: boolean, ordinal: number) {
		this.musicArtistId = musicArtistId;
		this.artistName = artistName;
		this.isFeaturedArtist = isFeaturedArtist;
		this.ordinal = ordinal;
	}

	private musicArtistId: string;
	private artistName: string;
	private isFeaturedArtist: boolean;
	private ordinal: number;

	public getMusicArtistId(): string {
		return this.musicArtistId;
	}

	public getArtistName(): string {
		return this.artistName;
	}

	public getIsFeaturedArtist(): boolean {
		return this.isFeaturedArtist;
	}

	public getOrdinal(): number {
		return this.ordinal;
	}
}

export class ReleaseTrackSongModel {
	constructor(
		releaseTrackId: string,
		releaseTrackSongId: string,
		songId: string,
		songName: string,
		trackLengthInSeconds: number,
		ordinal: number,
		musicArtists: ReleaseTrackMusicArtistModel[],
	) {
		this.releaseTrackId = releaseTrackId;
		this.releaseTrackSongId = releaseTrackSongId;
		this.songId = songId;
		this.songName = songName;
		this.trackLengthInSeconds = trackLengthInSeconds;
		this.ordinal = ordinal;
		this.musicArtists = musicArtists;
	}

	private releaseTrackId: string;
	private releaseTrackSongId: string;
	private songId: string;
	private songName: string;
	private trackLengthInSeconds: number;
	private ordinal: number;
	private musicArtists: ReleaseTrackMusicArtistModel[];

	public getReleaseTrackId(): string {
		return this.releaseTrackId;
	}

	public getReleaseTrackSongId(): string {
		return this.releaseTrackSongId;
	}

	public getSongId(): string {
		return this.songId;
	}

	public getSongName(): string {
		return this.songName;
	}

	public getTrackLengthInSeconds(): number {
		return this.trackLengthInSeconds;
	}

	public getOrdinal(): number {
		return this.ordinal;
	}

	public getMusicArtists(): ReleaseTrackMusicArtistModel[] {
		return this.musicArtists;
	}
}

export class ReleaseTrackTagModel {
	constructor(releaseTrackId: string, tagId: string, tagName: string) {
		this.releaseTrackId = releaseTrackId;
		this.tagId = tagId;
		this.tagName = tagName;
	}

	private releaseTrackId: string;
	private tagId: string;
	private tagName: string;

	public getReleaseTrackId(): string {
		return this.releaseTrackId;
	}

	public getTagId(): string {
		return this.tagId;
	}

	public getTagName(): string {
		return this.tagName;
	}
}

export class ReleaseTrackModel {
	constructor(
		releaseTrackId: string,
		programId: string,
		programName: string,
		programFormatId: string,
		programFormatName: string,
		programFormatTrackId: string,
		releaseId: string,
		releaseName: string,
		releaseNumber: number,
		releaseAlbumArtUrl: string,
		releaseAlbumArtThumbnailUrl: string,
		trackNumber: number,
		trackDesignation: string | null,
		releaseTrackSubtypeId: string | undefined,
		subtype: string | undefined,
		trackFocus: string,
		isBonusTrack: boolean,
		isAlternateTrack: boolean,
		averageRating: number | null,
		numberOfRatings: number | null,
		songs: ReleaseTrackSongModel[],
		exercises: ReleaseTrackExerciseModel[],
		tags: ReleaseTrackTagModel[],
	) {
		this.releaseTrackId = releaseTrackId;
		this.programId = programId;
		this.programName = programName;
		this.programFormatId = programFormatId;
		this.programFormatName = programFormatName;
		this.programFormatTrackId = programFormatTrackId;
		this.releaseId = releaseId;
		this.releaseName = releaseName;
		this.releaseNumber = releaseNumber;
		this.releaseAlbumArtUrl = releaseAlbumArtUrl;
		this.releaseAlbumArtThumbnailUrl = releaseAlbumArtThumbnailUrl;
		this.trackNumber = trackNumber;
		this.trackDesignation = trackDesignation;
		this.releaseTrackSubtypeId = releaseTrackSubtypeId;
		this.subtype = subtype;
		this.trackFocus = trackFocus;
		this.isBonusTrack = isBonusTrack;
		this.isAlternateTrack = isAlternateTrack;
		this.averageRating = averageRating;
		this.numberOfRatings = numberOfRatings;
		this.songs = songs;
		this.exercises = exercises;
		this.tags = tags;
	}
	private releaseTrackId: string;
	private programId: string;
	private programName: string;
	private programFormatId: string;
	private programFormatName: string;
	private programFormatTrackId: string;
	private releaseId: string;
	private releaseName: string;
	private releaseNumber: number;
	private releaseAlbumArtUrl: string;
	private releaseAlbumArtThumbnailUrl: string;
	private trackNumber: number;
	private trackDesignation: string | null;
	private releaseTrackSubtypeId: string | undefined;
	private subtype: string | undefined;
	private trackFocus: string;
	private isBonusTrack: boolean;
	private isAlternateTrack: boolean;
	private averageRating: number | null;
	private numberOfRatings: number | null;
	private songs: ReleaseTrackSongModel[];
	private exercises: ReleaseTrackExerciseModel[];
	private tags: ReleaseTrackTagModel[];

	public getReleaseTrackId(): string {
		return this.releaseTrackId;
	}

	public getProgramId(): string {
		return this.programId;
	}

	public getProgramName(): string {
		return this.programName;
	}

	public getProgramFormatId(): string {
		return this.programFormatId;
	}

	public getProgramFormatName(): string {
		return this.programFormatName;
	}

	public getProgramFormatTrackId(): string {
		return this.programFormatTrackId;
	}

	public getReleaseId(): string {
		return this.releaseId;
	}

	public getReleaseName(): string {
		return this.releaseName;
	}

	public getReleaseNumber(): number {
		return this.releaseNumber;
	}

	public getReleaseAlbumArtUrl(): string {
		return this.releaseAlbumArtUrl;
	}

	public getReleaseAlbumArtThumbnailUrl(): string {
		return this.releaseAlbumArtThumbnailUrl;
	}

	public getTrackNumber(): number {
		return this.trackNumber;
	}

	public getTrackDesignation(): string | null {
		return this.trackDesignation;
	}

	public getReleaseTrackSubtypeId(): string | undefined {
		return this.releaseTrackSubtypeId;
	}

	public getSubtype(): string | undefined {
		return this.subtype;
	}

	public getTrackFocus(): string {
		return this.trackFocus;
	}

	public getIsBonusTrack(): boolean {
		return this.isBonusTrack;
	}

	public getIsAlternateTrack(): boolean {
		return this.isAlternateTrack;
	}

	public getAverageRating(): number | null {
		return this.averageRating;
	}

	public setAverageRating(averageRating: number | null): void {
		this.averageRating = averageRating;
	}

	public getNumberOfRatings(): number | null {
		return this.numberOfRatings;
	}

	public setNumberOfRatings(numberOfRatings: number | null): void {
		this.numberOfRatings = numberOfRatings;
	}

	public getSongs(): ReleaseTrackSongModel[] {
		return this.songs;
	}

	public getExercises(): ReleaseTrackExerciseModel[] {
		return this.exercises;
	}

	public getTags(): ReleaseTrackTagModel[] {
		return this.tags;
	}

	public replaceTags(tags: { releaseTrackId: string; tagId: string; tagName: string }[]): void {
		this.tags = tags.map((tag) => new ReleaseTrackTagModel(tag.releaseTrackId, tag.tagId, tag.tagName));
	}

	public getTrackLengthInSeconds(): number {
		return this.songs.reduce((totalSeconds, song) => totalSeconds + song.getTrackLengthInSeconds(), 0);
	}
}
