import { ReleaseTrackModel } from './release-track.model';

export class ReleaseHeaderModel {
	constructor(
		releaseId: string,
		releaseNumber: number,
		releaseName: string,
		releaseYear: number | null,
		releaseMonth: number | null,
		programId: string,
		programName: string,
		programFormatId: string,
		programFormatName: string,
	) {
		this.releaseId = releaseId;
		this.releaseNumber = releaseNumber;
		this.releaseName = releaseName;
		this.releaseYear = releaseYear;
		this.releaseMonth = releaseMonth;
		this.programId = programId;
		this.programName = programName;
		this.programFormatId = programFormatId;
		this.programFormatName = programFormatName;
	}

	private releaseId: string;
	private releaseNumber: number;
	private releaseName: string;
	private releaseYear: number | null;
	private releaseMonth: number | null;
	private programId: string;
	private programName: string;
	private programFormatId: string;
	private programFormatName: string;

	getReleaseId(): string {
		return this.releaseId;
	}

	getReleaseNumber(): number {
		return this.releaseNumber;
	}

	getReleaseName(): string {
		return this.releaseName;
	}

	getReleaseYear(): number | null {
		return this.releaseYear;
	}

	getReleaseMonth(): number | null {
		return this.releaseMonth;
	}

	getProgramId(): string {
		return this.programId;
	}

	getProgramName(): string {
		return this.programName;
	}

	getProgramFormatId(): string {
		return this.programFormatId;
	}

	getProgramFormatName(): string {
		return this.programFormatName;
	}
}

export class ReleaseModel {
	constructor(
		releaseId: string,
		releaseNumber: number,
		releaseName: string,
		releaseYear: number | null,
		releaseMonth: number | null,
		releaseAlbumArtUrl: string,
		releaseAlbumArtThumbnailUrl: string,
		programId: string,
		programName: string,
		programFormatId: string,
		programFormatName: string,
		releaseTracks: ReleaseTrackModel[],
	) {
		this.releaseId = releaseId;
		this.releaseNumber = releaseNumber;
		this.releaseName = releaseName;
		this.releaseYear = releaseYear;
		this.releaseMonth = releaseMonth;
		this.releaseAlbumArtUrl = releaseAlbumArtUrl;
		this.releaseAlbumArtThumbnailUrl = releaseAlbumArtThumbnailUrl;
		this.programId = programId;
		this.programName = programName;
		this.programFormatId = programFormatId;
		this.programFormatName = programFormatName;
		this.releaseTracks = releaseTracks;
	}

	private releaseId: string;
	private releaseNumber: number;
	private releaseName: string;
	private releaseYear: number | null;
	private releaseMonth: number | null;
	private releaseAlbumArtUrl: string;
	private releaseAlbumArtThumbnailUrl: string;
	private programId: string;
	private programName: string;
	private programFormatId: string;
	private programFormatName: string;
	private releaseTracks: ReleaseTrackModel[];

	getReleaseId(): string {
		return this.releaseId;
	}

	getReleaseNumber(): number {
		return this.releaseNumber;
	}

	getReleaseName(): string {
		return this.releaseName;
	}

	getReleaseYear(): number | null {
		return this.releaseYear;
	}

	getReleaseMonth(): number | null {
		return this.releaseMonth;
	}

	getReleaseAlbumArtUrl(): string {
		return this.releaseAlbumArtUrl;
	}

	getReleaseAlbumArtThumbnailUrl(): string {
		return this.releaseAlbumArtThumbnailUrl;
	}

	getProgramId(): string {
		return this.programId;
	}

	getProgramName(): string {
		return this.programName;
	}

	getProgramFormatId(): string {
		return this.programFormatId;
	}

	getProgramFormatName(): string {
		return this.programFormatName;
	}

	getReleaseTracks(): ReleaseTrackModel[] {
		return this.releaseTracks;
	}
}
